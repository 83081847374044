import React from "react";

import "./src/styles/globals.css";

import AppRouting from "./src/web/AppRouting";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { initializeFirebaseApp, initializeFirestore } from "./src/utils/firebaseConfig";
import { AuthProvider } from "./src/providers/AuthProvider";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LoggerProvider } from "./src/providers/LoggerProvider";
import UpdatePopup from "./src/components/UpdatePopup";

// HACK: Fix Uncaught ReferenceError: Prism is not defined
import Prism from 'prismjs';
if (typeof window !== 'undefined' && typeof (window as any).Prism === 'undefined') {
  (window as any).Prism = Prism;
}

if (process.env.REACT_APP_PUBLIC_POSTHOG_ENABLED === "true") {
  const posthog_key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
  const posthog_host = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

  if (!posthog_key || !posthog_host) {
    throw new Error("Posthog key or host not found");
  }

  posthog.init(posthog_key, {
    api_host: posthog_host,
  });
}

// Initialize Firebase
initializeFirebaseApp();

// Initialize Firestore
initializeFirestore();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function(registration) {
      console.log('Registration successful, scope is:', registration.scope);
    }).catch(function(err) {
      console.log('Service worker registration failed, error:', err);
    });
}

const App = () => {
  const queryClient = new QueryClient();

  return (
    <>
      <LoggerProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              {process.env.REACT_APP_PUBLIC_POSTHOG_ENABLED !== "true" ? (
                <>
                  <AppRouting />
                  <UpdatePopup />
                </>
              ) : (
                <PostHogProvider client={posthog}>
                  <AppRouting />
                  <UpdatePopup />
                </PostHogProvider>
              )}
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
      </LoggerProvider>
    </>
  );
};

export default App;