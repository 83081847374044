import { create } from 'zustand'
import { Message } from 'ai'

interface ChatState {
  selectedGoal: string | null
  setSelectedGoal: (goal: string | null) => void
  isChatVisible: boolean
  setIsChatVisible: (visible: boolean) => void
  messages: Message[]
  setMessages: (messages: Message[]) => void
  addMessage: (message: Message) => void
}

export const useChatContext = create<ChatState>((set) => ({
  selectedGoal: null,
  setSelectedGoal: (goal) => set({ selectedGoal: goal }),
  isChatVisible: false,
  setIsChatVisible: (visible) => set({ isChatVisible: visible }),
  messages: [],
  setMessages: (messages) => set({ messages }),
  addMessage: (message) => set((state) => ({ messages: [...state.messages, message] })),
}))