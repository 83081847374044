import { useContext, createContext, useState, useEffect } from "react";
import { onAuthStateChanged, onIdTokenChanged, User, signInWithEmailAndPassword } from "firebase/auth";
import { initializeAuth } from "../utils/firebaseConfig";
import { usePostHog } from "posthog-js/react";

interface AuthContextProps {
  user: User | null;
  isAdmin: boolean;
  loading: boolean;
  handleSignIn: (email: string, password: string) => Promise<void>;
  errorMessage: string;
  token: string | null;
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  isAdmin: false,
  loading: true,
  handleSignIn: async () => {
    return Promise.resolve();
  },
  errorMessage: "",
  token: null,
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState<string | null>(null);

  const auth = initializeAuth();
  const posthog = usePostHog();

  useEffect(() => {
    const unsubscribeAuthState = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);

      if (user) {
        const email = user.email;
        posthog?.identify(user.uid, {
          email,
        });

        if (email) {
          const emailDomain = email.substring(email.lastIndexOf("@") + 1);
          const allowedDomains = ["lifehack.org"];
          const isAdmin = allowedDomains.includes(emailDomain);
          setIsAdmin(isAdmin);
          if (isAdmin) {
            posthog.opt_out_capturing();
          }
        } else {
          setIsAdmin(false);
        }
      } else {
        console.log("User is signed out");
        setToken(null);
      }
    });

    const unsubscribeIdToken = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const newToken = await user.getIdToken();
        setToken(newToken);
      } else {
        setToken(null);
      }
    });

    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, [auth, posthog]);

  const handleSignIn = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      setErrorMessage("");
      posthog?.identify(email);
      // Fetch token after successful sign-in
      const newToken = await userCredential.user.getIdToken(true);
      setToken(newToken);
    } catch (error) {
      console.error("Error signing in:", error);
      if (error instanceof Error) {
        if (error.message === 'Rejected') {
          setErrorMessage('Invalid email or password.');
        } else {
          setErrorMessage('An unknown error occurred.');
        }
      } else {
        setErrorMessage('An unknown error occurred.');
      }
    }
  };

  const value = { user, isAdmin, loading, handleSignIn, errorMessage, token };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}