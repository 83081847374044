import { AlertCircle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Offline } from "react-detect-offline";
import { Panel, PanelGroup } from "react-resizable-panels";

import { Alert, AlertDescription, AlertTitle } from "@shadcn/alert";
import { Drawer, DrawerContent } from "@shadcn/drawer";
import { ScrollArea } from "@shadcn/scroll-area";
import { Toaster } from "@shadcn/toaster";

import { useChatContext } from "@/web/hooks/useChatContext";
import ChatWindow from "../pages/Chat/ChatWindow";
import { Navbar } from "./Navbar";

type MainContainerProps = {
  children: React.ReactNode;
};

function OfflineAlertBox() {
  return (
    <Alert className="container mt-4 mb-4" variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>You have no connection</AlertTitle>
      <AlertDescription>
        Your data may not be saved. Please check your connection.
      </AlertDescription>
    </Alert>
  );
}

function MainContainer({ children }: MainContainerProps) {
  const [isMobile, setIsMobile] = useState(false);
  const { selectedGoal, setSelectedGoal, isChatVisible, setIsChatVisible } =
    useChatContext();

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <div className="flex h-screen flex-col">
      <Toaster />
      <Navbar />
      <PanelGroup direction="horizontal" className="flex-1">
        <Panel
          defaultSize={100}
          minSize={60}
          className="transition-[flex-basis] duration-300 ease-in-out"
        >
          <ScrollArea className="h-full">
            <div className="mx-auto max-w-[1000px] space-y-8 p-4">
              <Offline>
                <OfflineAlertBox />
              </Offline>
              {children}
            </div>
          </ScrollArea>
        </Panel>
        {!isMobile && (
          <>
            {/* <PanelResizeHandle className="w-1 bg-muted" /> */}
            <Panel
              defaultSize={0}
              minSize={30}
              collapsible={true}
              className="transition-[flex-basis] duration-300 ease-in-out"
              style={
                {
                  "--panel-size": isChatVisible ? "500px" : "0px",
                  flexBasis: "var(--panel-size)",
                } as React.CSSProperties
              }
            >
              <div className="h-full border-l">
                  <ChatWindow
                    goal={selectedGoal ?? ""}
                    onClose={() => {
                      setIsChatVisible(false);
                      setSelectedGoal(null);
                    }}
                  />
              </div>
            </Panel>
          </>
        )}
      </PanelGroup>
      {isMobile && (
        <Drawer open={isChatVisible} onOpenChange={setIsChatVisible}>
          <DrawerContent className="h-[95%] max-h-[95%]">
              <ChatWindow
                goal={selectedGoal ?? ""}
                onClose={() => {
                  setIsChatVisible(false);
                  setSelectedGoal(null);
                }}
              />
          </DrawerContent>
        </Drawer>
      )}
    </div>
  );
}

export default MainContainer;
