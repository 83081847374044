import { Card, CardContent } from "@shadcn/card";
// import { Button } from "@shadcn/button";
import { CompassIcon } from "lucide-react";

interface BigNorthStarEmptyProps {
  onEdit: () => void;
}

export function BigNorthStarEmpty({ onEdit: _onEdit }: BigNorthStarEmptyProps) {
  return (
    <Card className="w-full">
      <CardContent className="flex flex-col items-center justify-center gap-4 p-10">
        <CompassIcon className="h-12 w-12 text-muted-foreground" />
        <div className="space-y-1 text-center">
          <h3 className="text-xl font-semibold">Add Your Big Northstar</h3>
          <p className="text-muted-foreground">Your coach should have recommended one for you. Read your Lifehack 360 Report below to add your Big Northstar.</p>
        </div>
        {/* <Button onClick={onEdit}>Choose Big Northstar</Button> */}
      </CardContent>
    </Card>
  );
}