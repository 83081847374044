/**
 * v0 by Vercel.
 * @see https://v0.dev/t/S63uZW1Cbks
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import UserMessagePopup from "@components/UserMessagePopup"; // Import the popup component
import VimeoVideoEmbed from "@components/VimeoVideoEmbed"; // Import the Vimeo video embed component
import { Button } from "@shadcn/button";
import { ClipboardCheckIcon } from "lucide-react";
import { useState } from "react";

export default function ProcessingScreen() {
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

  const handleOpenPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  return (
    <div className="flex h-full flex-col items-center justify-center gap-6 p-8 md:p-12">
      <div className="flex flex-col items-center gap-4 text-center">
        <ClipboardCheckIcon className="h-12 w-12 text-gray-500 dark:text-gray-400" />
        <h3 className="text-2xl font-bold">
          Your 360 Assessment is Processing
        </h3>
        <p className="max-w-md text-gray-500 dark:text-gray-400">
          Your 360 Assessment is complete and currently under review by your
          coach. You can expect to receive your report within the next 24 hours.
          If you don’t receive it by then, please reach out to us.
        </p>
        <p className="max-w-md text-gray-500 dark:text-gray-400">
          In the meantime, we recommend watching the onboarding video below to
          help you get started.
        </p>
      </div>
      <div className="w-full max-w-2xl">
        <VimeoVideoEmbed videoId="1003751857" title="Onboarding Video" />
      </div>
      <Button variant="outline" onClick={handleOpenPopup}>
        Message Your Coach
      </Button>

      {showPopup && <UserMessagePopup onClose={handleClosePopup} />}
    </div>
  );
}
