import { ToolInvocation } from "ai"

interface AiToolExecutionInfoProps {
  executions: ToolInvocation[]
}

export function AiToolExecutionInfo({ executions }: AiToolExecutionInfoProps) {
  return (
    <div className="ai-tool-executions">
      {executions.map((execution, index) => (
        <div key={index} className="ai-tool-execution">
          {execution.toolName === "exa" && (execution.state === "partial-call" || execution.state === "call") && (
            <span className="text-sm text-gray-500">Searching knowledge online...</span>
          )}
          {execution.toolName === "internal-rag" && (execution.state === "partial-call" || execution.state === "call") && (
            <span className="text-sm text-gray-500">Searching Lifehack knowledge...</span>
          )}
          { execution.state === "result" && (
            <span className="text-sm text-gray-500">Found relevant results. Processing...</span>
          )}
          {/* {execution.state === "result" && (
            <div className="px-4 py-2 text-sm text-gray-600 overflow-x-auto">
              <div className="flex space-x-2">
                {JSON.parse(execution.result).map((item: AiToolDocument, idx: number) => (
                  <a
                    key={idx}
                    href={item.metadata.source}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-shrink-0 p-2 bg-gray-100 rounded-md w-40 h-20 hover:bg-gray-200 transition-colors cursor-pointer"
                  >
                    <p className="text-xs text-gray-600 truncate">
                      {item.metadata.title}
                    </p>
                    <p className="text-xs text-gray-400 truncate">
                      {item.metadata.source}
                    </p>
                  </a>
                ))}
              </div>
            </div>
          )} */}
        </div>
      ))}
    </div>
  )
}