import { Sparkles } from "lucide-react";
import { useChatContext } from "@/web/hooks/useChatContext";
import { useCallback } from "react";

function AskCoach() {
  const { setSelectedGoal, isChatVisible, setIsChatVisible } = useChatContext();

  const handleSelectCard = useCallback((goal: string) => {
    setSelectedGoal(goal);
    if (!isChatVisible) {
      setTimeout(() => setIsChatVisible(true), 0);
    }
  }, [isChatVisible, setSelectedGoal, setIsChatVisible]);

  const cards = [
    "What's the most impactful thing I can do today for my Big Northstar?",
    "Can you remind me why I chose this Big Northstar in the first place?",
    "I'm feeling stuck. How can I break down my Big Northstar into smaller steps?",
    "What's one habit I could start that would significantly impact my Big Northstar?",
    "I’m feeling overwhelmed. What’s the one thing I should focus on right now to move closer to my Big Northstar?",
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
      <div
        key="anything"
        className="p-4 border rounded-lg shadow-sm hover:shadow-md flex items-center space-x-2 cursor-pointer"
        onClick={() => handleSelectCard("")}
      >
        <Sparkles className="text-slate-400" />
        <span>Ask Anything</span>
      </div>
      {cards.map((goal, index) => (
        <div
          key={index}
          className="p-4 border rounded-lg shadow-sm hover:shadow-md flex items-center space-x-2 cursor-pointer"
          onClick={() => handleSelectCard(goal)}
        >
          <Sparkles className="text-slate-400" />
          <span>{goal}</span>
        </div>
      ))}
    </div>
  );
}

export default AskCoach;
