import { UseMutationResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  DocumentReference,
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { Prompt, PromptAnswer, PromptResponse } from "@shared/types";
import { getFirestoreDb } from "../utils/firebase";
import { captureException } from "../utils/logging";
import { setupConfig } from "./apiUtils";
import { QueryDocumentSnapshot } from "firebase/firestore";

export {
  useAddPromptAnswer,
  useEditPromptAnswerById,
  useEmailHumanCoach,
  useFetchAllPromptAnswers,
  useFetchPromptAnswerById,
  useFetchPromptAnswerByIsDashboard,
  useFetchAllResponsesByPromptAnswerId,
  useAddPromptResponse,
  //Admin
  useFetchPromptAnswersByUserId,
  useAddPromptResponseByAdmin,
  useFetchPromptAnswersNeedCoach,
};

interface EmailHumanCoachInput {
  promptId: string;
  question: string;
}

// Email Human Coach
const useEmailHumanCoach = () => {
  return useMutation({
    mutationFn: async ({ promptId, question }: EmailHumanCoachInput): Promise<string> => {
      const url = `${process.env.REACT_APP_INTERNAL_API_URL}/v1/prompts/ask_coach`;
      try {
        const config = await setupConfig();
        const { data } = await axios.post(url, { promptId, question }, config);
        return data as string;
      } catch (error) {
        captureException("error calling transcript ai api", error as Error);
        throw error;
      }
    },
  });
};

// Hook to use the getAllPromptAnswers function with React Query
const useFetchAllPromptAnswers = () => {
  return useQuery<PromptAnswer[], Error>({
    queryKey: ["promptAnswers"],
    queryFn: async (): Promise<PromptAnswer[]> => {
      try {
        const { db, userId } = getFirestoreDb();

        const promptAnswersRef = collection(db, `users/${userId}/promptAnswers`);
        const querySnapshot = await getDocs(promptAnswersRef);
        const promptAnswers = querySnapshot.docs.map((doc: QueryDocumentSnapshot) => {
          const data = doc.data() as PromptAnswer;
          return { ...data, id: doc.id };
        });
        return promptAnswers;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Firestore operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
  });
};

const useFetchAllResponsesByPromptAnswerId = (promptAnswerId: string) => {
  return useQuery<PromptResponse[], Error>({
    queryKey: ["promptAnswers", promptAnswerId, "responses"],
    queryFn: async (): Promise<PromptResponse[]> => {
      try {
        const { db, userId } = getFirestoreDb();
        const responsesRef = collection(db, `users/${userId}/promptAnswers/${promptAnswerId}/responses`);
        const querySnapshot = await getDocs(responsesRef);
        const responses = querySnapshot.docs.map((doc: QueryDocumentSnapshot) => {
          const data = doc.data() as PromptResponse;
          return { ...data, id: doc.id };
        });
        return responses;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Firestore operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
  });
};


const useAddPromptResponse = (): UseMutationResult<
  DocumentReference,
  Error,
  { promptAnswerId: string; newResponse: PromptResponse }
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      promptAnswerId,
      newResponse,
    }: {
      promptAnswerId: string;
      newResponse: PromptResponse;
    }): Promise<DocumentReference> => {
      try {
        const { db, userId } = getFirestoreDb();

        const responsesRef = collection(db, `users/${userId}/promptAnswers/${promptAnswerId}/responses`);
        const docRef = await addDoc(responsesRef, {
          ...newResponse,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        return docRef;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Firestore operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
    onSuccess: (_, { promptAnswerId }) => {
      // Invalidate and refetch queries after a successful mutation
      queryClient.invalidateQueries({ queryKey: ["promptAnswers", promptAnswerId, "responses"] });
    },
  });
};

// Admin call
const useFetchPromptAnswersByUserId = (userId: string) => {
  return useQuery<PromptAnswer[], Error>({
    queryKey: ["promptAnswers", userId],
    queryFn: async (): Promise<PromptAnswer[]> => {
      try {
        const { db } = getFirestoreDb();

        const promptAnswersRef = collection(db, `users/${userId}/promptAnswers`);
        const querySnapshot = await getDocs(promptAnswersRef);
        const promptAnswers = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data() as PromptAnswer;
            // Fetch responses subcollection
            const responsesRef = collection(db, `users/${userId}/promptAnswers/${doc.id}/responses`);
            const responsesSnapshot = await getDocs(responsesRef);
            const responses = responsesSnapshot.docs.map(
              (responseDoc) =>
                ({
                  id: responseDoc.id,
                  ...responseDoc.data(),
                }) as PromptResponse
            );

            // Add responses to the promptAnswer
            data.responses = responses;
            return { ...data, id: doc.id };
          })
        );

        return promptAnswers;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Firestore operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
  });
};

// Admin Call: Add a prompt response by prompt answer ID and user ID
const useAddPromptResponseByAdmin = (): UseMutationResult<
  DocumentReference,
  Error,
  { userId: string; promptAnswerId: string; newResponse: PromptResponse; isWaitingHumanCoach?: boolean }
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      userId,
      promptAnswerId,
      newResponse,
      isWaitingHumanCoach = true,
    }: {
      userId: string;
      promptAnswerId: string;
      newResponse: PromptResponse;
      isWaitingHumanCoach?: boolean;
    }): Promise<DocumentReference> => {
      try {
        const { db } = getFirestoreDb();

        const responsesRef = collection(db, `users/${userId}/promptAnswers/${promptAnswerId}/responses`);
        const docRef = await addDoc(responsesRef, {
          ...newResponse,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        if (!isWaitingHumanCoach) {
          // Update isWaitingHumanCoach in the parent promptAnswer document
          const promptAnswerRef = doc(db, `users/${userId}/promptAnswers/${promptAnswerId}`);
          await updateDoc(promptAnswerRef, {
            isWaitingHumanCoach: false,
          });
        }

        return docRef;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Firestore operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
    onSuccess: () => {
      // Invalidate and refetch queries after a successful mutation
      queryClient.invalidateQueries({ queryKey: ["promptAnswers", "responses"] });
    },
  });
};

const useFetchPromptAnswerById = (promptAnswerId: string) => {
  return useQuery<PromptAnswer, Error>({
    queryKey: ["promptAnswers", promptAnswerId],
    queryFn: async (): Promise<PromptAnswer> => {
      try {
        const { db, userId } = getFirestoreDb();

        const promptAnswerRef = doc(db, `users/${userId}/promptAnswers/${promptAnswerId}`);
        const docSnap = await getDoc(promptAnswerRef);

        if (docSnap.exists()) {
          const promptAnswer = docSnap.data() as PromptAnswer;
          promptAnswer.id = docSnap.id; // Add id to the promptAnswer

          // Fetch responses subcollection
          const responsesRef = collection(db, `users/${userId}/promptAnswers/${promptAnswerId}/responses`);
          const responsesSnapshot = await getDocs(responsesRef);
          const responses = responsesSnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              }) as PromptResponse
          );

          // Add responses to the promptAnswer
          promptAnswer.responses = responses;

          return promptAnswer;
        }

        throw new Error("No such document!");
      } catch (error) {
        if (error instanceof Error) {
          console.error("Fetch Prompt operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
    // FIXME: this is for avoid the report animation is shown again
    refetchOnWindowFocus: false,
  });
};

// Fetch the first prompt answer that isDashboard is true
const useFetchPromptAnswerByIsDashboard = () => {
  return useQuery<PromptAnswer | undefined, Error>({
    queryKey: ["promptAnswers", "isDashboard"],
    queryFn: async (): Promise<PromptAnswer | undefined> => {
      try {
        const { db, userId } = getFirestoreDb();

        const promptAnswersRef = collection(db, `users/${userId}/promptAnswers`);
        const dashboardQuery = query(
          promptAnswersRef,
          where("isDashboard", "==", true),
          orderBy("createdAt", "desc"),
          limit(1)
        );

        const dashboardDoc = await getDocs(dashboardQuery).then(snapshot => snapshot.docs[0]);

        if (dashboardDoc) {
          const promptAnswer = dashboardDoc.data() as PromptAnswer;
          promptAnswer.id = dashboardDoc.id;

          // Fetch responses subcollection
          const responsesRef = collection(db, `users/${userId}/promptAnswers/${dashboardDoc.id}/responses`);
          const responsesSnapshot = await getDocs(responsesRef);
          const responses = responsesSnapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              }) as PromptResponse
          );

          // Add responses to the promptAnswer
          promptAnswer.responses = responses;

          return promptAnswer;
        }

        return undefined;
        
      } catch (error) {
        if (error instanceof Error) {
          console.error("Fetch Prompt operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
    refetchOnWindowFocus: false,
  });
};

// Hook to add a prompt answer with React Query
const useAddPromptAnswer = (): UseMutationResult<DocumentReference, Error, Prompt> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newPromptAnswer: Prompt): Promise<DocumentReference> => {
      try {
        const { db, userId } = getFirestoreDb();

        const promptAnswersRef = collection(db, `users/${userId}/promptAnswers`);
        const docRef = await addDoc(promptAnswersRef, {
          ...newPromptAnswer,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        return docRef;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Firestore operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
    onSuccess: () => {
      // Invalidate and refetch queries after a successful mutation
      queryClient.invalidateQueries({ queryKey: ["promptAnswers"] });
    },
  });
};

// Hook to edit a prompt answer by ID
const useEditPromptAnswerById = (): UseMutationResult<
  void,
  Error,
  { promptAnswerId: string; updatedPromptAnswer: PromptAnswer }
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      promptAnswerId,
      updatedPromptAnswer,
    }: {
      promptAnswerId: string;
      updatedPromptAnswer: PromptAnswer;
    }): Promise<void> => {
      try {
        const { db, userId } = getFirestoreDb();
        const promptAnswerRef = doc(db, `users/${userId}/promptAnswers/${promptAnswerId}`);
        await setDoc(promptAnswerRef, {
          ...updatedPromptAnswer,
          updatedAt: serverTimestamp(),
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error("Firestore operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
    onSuccess: () => {
      // Invalidate and refetch queries after a successful mutation
      queryClient.invalidateQueries({ queryKey: ["promptAnswers"] });
    },
  });
};

const useFetchPromptAnswersNeedCoach = () => {
  return useQuery<{ userId: string; email: string; promptAnswer: PromptAnswer }[], Error>({
    queryKey: ["promptAnswers", "needAnswer"],
    queryFn: async (): Promise<{ userId: string; email: string; promptAnswer: PromptAnswer }[]> => {
      try {
        const { db } = getFirestoreDb();
        const promptAnswersQuery = query(
          collectionGroup(db, "promptAnswers"),
          where("isWaitingHumanCoach", "==", true)
        );
        const querySnapshot = await getDocs(promptAnswersQuery);

        const results = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const promptAnswer = doc.data() as PromptAnswer;
            const userId = doc.ref.path.split("/")[1]; // Extract userId from the document path
            promptAnswer.id = doc.id;

            let email = "";
            if (doc.ref.parent.parent) {
              // Get user document to retrieve email
              const userDoc = await getDoc(doc.ref.parent.parent);
              email = userDoc.data()?.email;
            }
            // Fetch responses subcollection
            const responsesRef = collection(db, `users/${userId}/promptAnswers/${doc.id}/responses`);
            const responsesSnapshot = await getDocs(responsesRef);
            const responses = responsesSnapshot.docs.map(
              (responseDoc) =>
                ({
                  id: responseDoc.id,
                  ...responseDoc.data(),
                }) as PromptResponse
            );

            // Add responses to the promptAnswer
            promptAnswer.responses = responses.length > 0 ? responses : [];

            return { userId, email, promptAnswer };
          })
        );

        return results;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Fetch Prompt operation failed: ", error.message);
        } else {
          console.error("An unknown error occurred: ", error);
        }
        throw error;
      }
    },
  });
};
