import { Lightbulb } from 'lucide-react'

export function EmptyState() {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center p-4">
      <Lightbulb className="w-12 h-12 text-secondary mb-4" />
      <h3 className="text-lg font-semibold mb-2">Welcome to LifeHack AI Coach</h3>
      <p className="text-sm text-gray-600">
        I understand your big northstar goal and can provide feedback on it. 
        Feel free to ask me anything about your goals or progress!
      </p>
    </div>
  )
}